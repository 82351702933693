import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './index.css';

import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';
import App from './App';
import store from './store/index';
import './utilities/i18n';


import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Provider store={store}>
  <Suspense fallback={<div className='center'><LoadingSpinner /></div>}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>
</Provider>
,
document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
