import React from 'react';
import QASessionItem from './QASessionItem';


import styles from './QASessionList.module.css'


const QASessionList = ({sessions, selectedSession}) => {

    console.log(sessions);

    return (
        <section id={styles.sessionListWrapper}>
            <div className={styles.sessionListContainer}>
            <h1>Please Select A Q&A Session</h1>
                {sessions && sessions.map(session => (
                 <QASessionItem key={session.id} session={session} selectedSession={selectedSession} />
                ))}
            </div>
        </section>
    )
}

export default QASessionList;