import React, { Fragment, useState, useEffect, useContext } from 'react';
import { faComments, faList, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, AnimatePresence } from 'framer-motion';
import { SocketContext } from '../../context/socket';

import { useHttpClient } from '../../hooks/http-hook';
import { AuthContext } from '../../context/auth-context';
import ErrorModal from '../UIElements/Modal/ErrorModal';
import LoadingSpinner from '../UIElements/LoadingSpinner';
import QASubmitForm from './QASubmitForm';
import QABody from './QABody';
import styles from './QandA.module.css';
import NoSessionsNotice from './NoSessionsNotice';
import QASessionList from './QASessionList';

const variants = {
  open: { opacity: 1, right: 0 },
  closed: { width:'0', opacity: 0, right: -500 },
};

const QandA = ({ eventId }) => {

  const { REACT_APP_API_URL } = process.env;
  const { isLoading, sendRequest, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const socket = useContext(SocketContext);
  const [isOpen, setIsOpen] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState();
  const [sessionTitle, setSessionTitle] = useState('Select a Q&A Session');
  const [updateQuestion, setUpdateQuestion] = useState();

  const [sessionState, setSessionState] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState();
  const [approvedQuestion, setApprovedQuestion] = useState();

  const updateQuestions = data => {
    const sid = getCurentSessionId();
    if (data && sid) {
      if (data.question) {
        if (data.question.sessionId === sid) {
          switch (data.action) {
            case 'new':
              setNewQuestion(data.question);
              break;

            case 'approved':
              setApprovedQuestion(data.question);
              break;

            case 'new answer':
              setUpdateQuestion(data.question);
              break;

            case 'selected':
              setUpdateQuestion(data.question);
              break;

            case 'answered':
              setUpdateQuestion(data.question);
              break;

            default:
              break;
          }
        }
      }
      if (data.action === 'session toggle') {
        const sid = getCurentSessionId();
        if (data.sessionId === sid) {
          toggleSessionState(data.isLive);
        }
      }
    }
  };

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const responseData = await sendRequest(
          `${REACT_APP_API_URL}/api/qa/qa-sessions`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        if (responseData.sessions) {
          setSessions(() => [...responseData.sessions]);
          if (responseData.sessions.length === 1) {
            selectedSessionHandler(responseData.sessions[0]);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchSessions();
  }, []);

  // listen for new questions on WS
  useEffect(() => {
    socket.on('Q&A', dataFromServer => {
      updateQuestions(dataFromServer);
    });

    return () => {
      socket.off('Q&A');
    }
  }, [socket]);

  const selectedSessionHandler = async session => {
    setSessionTitle(session.name);

    try {
      const responseData = await sendRequest(
        `${REACT_APP_API_URL}/api/qa/qa-session/${session.id}`,
        'POST',
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );

      if (responseData.session) {
        setSelectedSession(responseData.session);
        setSessionState(responseData.session.live);
        localStorage.setItem(
          'sessionData',
          JSON.stringify({ sid: responseData.session.id })
        );
        setQuestions(() => [...responseData.session.qaQuestions]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const resetView = () => {
    setSelectedSession(null);
    setSessionTitle('Select a Q&A Session');
    setQuestions([]);
  };

  useEffect(() => {
    updateQuestion && addAnswer();
  }, [updateQuestion]);

  useEffect(() => {
    newQuestion && addQuestion(newQuestion);
  }, [newQuestion]);

  useEffect(() => {
    approvedQuestion && approveQuestion(approvedQuestion);
  }, [approvedQuestion]);

  

  const addQuestion = (newQuestionData) => {
    const duplicate = questions.find(q => q.id === newQuestionData.id);
    console.log(duplicate);

    if (!duplicate) {
      if (newQuestionData.authorId === auth.userId) {
        setQuestions(currentQs => [newQuestionData, ...currentQs]);
      }
    }

  } 

  const approveQuestion = question => {
    if (question.authorId !== auth.userId ) {
      const duplicate = questions.find(q => q.id === question.id);
      if (!duplicate) {
        setQuestions(currentQs => [question, ...currentQs]);
      }
    }
  }

  const addAnswer = () => {
    let updatedQuestions = questions;
    let question = updatedQuestions.find(q => {
      return q.id === updateQuestion.id;
    });

    if (question) {
      updateQuestion.approved && (question.approved = updateQuestion.approved);
      updateQuestion.answered && (question.answered = updateQuestion.answered);
      updateQuestion.answeredAt && (question.answeredAt = updateQuestion.answeredAt);
      updateQuestion.selected && (question.selected = updateQuestion.selected);
      updateQuestion.selectedAt && (question.selectedAt = updateQuestion.selectedAt);
      updateQuestion.qaAnswers &&(question.qaAnswers = updateQuestion.qaAnswers);
    }
    setQuestions([...updatedQuestions]);
  };

  const toggleSessionState = isLive => {
    setSessionState(isLive);
  };

  const getCurentSessionId = () => {
    const data = localStorage.getItem('sessionData');

    if (data) {
      return JSON.parse(data).sid;
    }
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div id={styles.qaWrapper} onClick={() => setIsOpen(isOpen => !isOpen)}>
        <FontAwesomeIcon icon={faComments} />
        <span>Q&A</span>
      </div>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key="drawer"
          className={styles.qaDrawWrapper}
          animate={isOpen ? 'open' : 'closed'}
          variants={variants}
        >
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>{sessionTitle}</h1>
          </div>

          <div className={styles.qaBodyWrapper}>
            {!sessions && (
              <NoSessionsNotice
                title={'Closed'}
                noticeText={'All sessions are currently closed.'}
              />
            )}
            {questions && selectedSession && !isLoading && (
              <QABody questionList={questions} userId={auth.userId} sessionState={sessionState}/>
            )}
            {isLoading && (
              <div className={styles.centerSpinner}>
                <div className={`center ${styles.loadingSpinner}`}>
                  <LoadingSpinner />
                  <p className={styles.loadingText}>Please Wait...</p>
                </div>
              </div>
            )}
            {sessions && !selectedSession && !isLoading && (
              <QASessionList
                sessions={sessions}
                selectedSession={selectedSessionHandler}
              />
            )}
          </div>

          <div className={styles.editorWrapper}>
          {selectedSession && (
            <div className={styles.askQuestionForm}>
                <QASubmitForm
                  sessionId={selectedSession.id}
                  isOpen={sessionState}
                />
            </div>
            )}
            <div className={styles.closeWrapper}>
                <div className={styles.closeBtn} onClick={() => setIsOpen(isOpen => !isOpen)}>
                  <span className={styles.closeIcon}><FontAwesomeIcon icon={faTimes} /></span>
                  <p  className={styles.closeText}>Close</p>
                </div>

                {sessions.length > 1 && <div className={styles.sessionBtn} onClick={resetView}>
                  <span className={styles.sessionIcon}><FontAwesomeIcon icon={faList} /></span>
                  <p  className={styles.sessionText}>Sessions</p>
                </div>}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </Fragment>
  );
};

export default QandA;
