import { createStore } from 'redux';

const languageReducer = (state = { lang: 'en'}, action) => {
    console.log('languageReducer was called with state', state, 'and action', action.lang)
    if (action.type === 'updateLanguage') {
      return { 
          lang: state.lang = action.lang
        };
    } else {
        return state;
    }
  }
  
  const store = createStore(languageReducer);


  export default store;