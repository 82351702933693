import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

import { useTranslation } from 'react-i18next';
import CountDown from '../../shared/components/UIElements/Countdown/CountDown';


import styles from './HoldingHero.module.css';

const HoldingHero = ({event}) => {

    const { t } = useTranslation();

    const registerHandler = () => {
        console.log('register');
    }

    return (
        <section id={styles.hero} style={{ 
            backgroundImage: `url(${process.env.PUBLIC_URL + 'img/event-bw.png'})` 
          }}>
          <div id={styles.bgOverlay}></div>
          <div className={styles.content}>
          <h1 className={styles.title}>{t('content.upcoming webinair')}</h1>
          <h2 className={styles.subTitle} >{t('content.event starts in')}:</h2>
          <CountDown eventDate={event.date} />
          <button  type='button' onClick={registerHandler} className={styles.buttonSecondary} > <FontAwesomeIcon icon={faDoorOpen}/> <span> {t('register')}</span></button> 
            <p className={styles.text}>{t('content.hero text')}</p>
          </div>
           
        </section>
    )
}


export default HoldingHero;