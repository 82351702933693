import React, { Suspense } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { SocketContext, socket } from './shared/context/socket';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faTrashAlt,
  faKey,
  faCheck,
  faCalendarAlt,
  faPoundSign,
  faSignInAlt,
  faSignOutAlt,
  faEnvelope,
  faExclamationTriangle,
  faPoll
} from '@fortawesome/free-solid-svg-icons';

import Auth from './auth/pages/AuthTicket';
import OnDemand from './onDemand/pages/OnDemand';
import Event from './event/pages/Event';
import Holding from './holdingPage/pages/Holding';

import Header from './shared/components/Header/MainHeader';
import Footer from './shared/components/Footer/MainFooter';
import { useAuth } from './shared/hooks/auth-hook';
import { AuthContext } from './shared/context/auth-context';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';

import './App.css';


library.add(
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  fab,
  faTrashAlt,
  faKey,
  faCheck,
  faSignOutAlt,
  faCalendarAlt,
  faSearch,
  faEnvelope,
  faPoundSign,
  faSignInAlt,
  faExclamationTriangle,
  faPoll
);

function App() {

  const { userId, email, token, lang, login, logout } = useAuth();

  const dispatch = useDispatch();

  socket.onAny((event, ...args) => {
    console.log(event, args);
  });

  
  const langCookie = Cookies.get('i18next')
  if (lang ) {
    dispatch({ type: 'updateLanguage', lang: lang})
  }
  else if (langCookie) {
    dispatch({ type: 'updateLanguage', lang: langCookie})
  } else {
    dispatch({ type: 'updateLanguage', lang: 'en'})
  }


  let routes;

  token
    ? (routes = (
      
          <Switch>
            <Route path="/" exact>
              <Event />
            </Route>
            <Route path="/:category/:itemName" >
              <OnDemand />
            </Route>
            <Route path="*">
            <Event />
            </Route>
          </Switch>
      
      ))
    : (routes = (
          <Switch>
            <Route path="/" exact>
              <Holding />
            </Route>
            <Route path="/auth" exact>
              <Auth />
            </Route>
            <Route path="*">
              <Auth />
            </Route>
          </Switch>
      ));

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        lang: lang,
        login: login,
        logout: logout,
      }}
    >
   
      <Router>
      <SocketContext.Provider value={socket}>
        <Header />
        <main>
          <Suspense
            fallback={
              <div className="center">
                <LoadingSpinner />
              </div>
            }
          >
          
            {routes}
          
          </Suspense>
        </main>
        <Footer />
        </SocketContext.Provider>
      </Router>
     
    </AuthContext.Provider>
  );
}

export default App;
