import React, {useState, useContext} from 'react';
import {  faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoadingDots from '../UIElements/LoadingDots';
import ErrorModal from '../UIElements/Modal/ErrorModal';
import { useHttpClient } from '../../hooks/http-hook';
import { AuthContext } from '../../context/auth-context';

import Input from '../FormElements/Input';
import styles from './QASubmitForm.module.css';
import { Fragment } from 'react';


const QASubmitForm = ({isOpen, sessionId}) => {

    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
   
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [question, setQuestion] = useState('');


    const updateQuestionHandler = event => {
        setQuestion(event.target.value);
    }


    const askQuestionHandler = async event => {
        event.preventDefault()

        console.log(sessionId);
        console.log(event.target.question.value);
        console.log(auth.userId);

        try {
            const response = await sendRequest(
              `${REACT_APP_API_URL}/api/qa/create-question`,
              'POST',
              JSON.stringify({
                sessionId: sessionId,
                question: event.target.question.value,
                authorId: auth.userId,
              }),
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
              );
              console.log(response);
              setQuestion('')
              
          }
          catch (err) {
            console.log(err);
          }
    }

    return (
        <Fragment>

            <ErrorModal
                error={error}
                className={'fm-modal-sm'}
                onClear={clearError}
            />
           {isOpen ? <form className={styles.questionForm} autoComplete="off" onSubmit={askQuestionHandler}>
                <Input inputClassName={styles.questionInput} id='question' value={question} onChange={updateQuestionHandler} name='question' type='text' autoComplete='off'  placeholder='Ask a  question'/>
                {!isLoading && question && <button className={styles.askButton} ><FontAwesomeIcon icon={faPaperPlane}/></button>}
                {isLoading && <LoadingDots className={styles.loadingDots} />}
            </form> 
            : 
            <div className={styles.closedWrapper}><h1 className={styles.closedTitle}>Session Closed.</h1></div>
            }

        </Fragment>
    )
}

export default QASubmitForm;