import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer   } from 'recharts';
import io from 'socket.io-client';

import styles from './PollChart.module.css';
  
const COLORS = ['#C21807', '#af1606', '#9b1306', '#881105' , '#740e04' , '#610c04' , '#4e0a03'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, option }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text className={styles.pieText} x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${option} ${(percent * 100).toFixed(0)}%  `} 
    </text>
  );
};


const PollChart = ({poll}) => {

    const { REACT_APP_API_URL } = process.env;
    const socket = io(REACT_APP_API_URL);
    const [option, setOptions] = useState([]);
    const [totalVotes, setTotalVotes] = useState(0);
    const [isPie, setIsPie] = useState(false);

    useEffect(() => {
        if (poll) {
            if (poll.pollElements ) {
               sortData(poll.pollElements)
            }

            setIsPie(poll.isPie);
        }
      }, [poll]);

      useEffect(() => {
        socket.on('votes', dataFromServer => {
            updatePolls(dataFromServer.data);
          });
      }, [])

      const sortData = data => {
        const sortedOptions = data.sort( compare );
          let total = 0;
          sortedOptions.map(option => {
          return total = total + option.value
        })
       
       sortedOptions.map(option => {
          console.log(option.value);
          const percent = ( 100 / total ) * option.value
          console.log(percent);
          return option.value = percent
        })
        setTotalVotes(total);
        setOptions(sortedOptions);
        console.log(sortedOptions);
      }

      const updatePolls = data => {
        sortData(data);   
      }

      function compare( a, b ) {
        if ( a.label < b.label ){
          return -1;
        }
        if ( a.label > b.label ){
          return 1;
        }
        return 0;
      }

      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className={styles.tooltipWrapper}>
              {label ? <p className={styles.title}>{`${label}`}</p> : <p className={styles.title}>{`${payload[0].payload.option}`}</p>}
              <p className={styles.value}>{`${payload[0].value.toFixed(0)}%`}</p>
            </div>
          );
        }
        return null;
      };

    return (
        <div className={styles.pollChartContainer}>

           {option && !isPie && 
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={500} height={300} data={option} >
                  <CartesianGrid strokeDasharray="4 4" color="#fff"/>
                  <XAxis dataKey="option" tick={{ fill: 'white' }} />
                  <YAxis type="number" tick={{ fill: 'white' }} domain={[0, 100]}/>
                  <Tooltip content={<CustomTooltip />} cursor={{fill: 'rgba(255,255,255, .2)'}} />
                  <Bar dataKey="value" fill="#C21807" />
                </BarChart>
              </ResponsiveContainer>
            }

            {option && isPie && 
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={500} height={500} data={option} >
                  
                  <Pie 
                    data={option}
                    fill="#C21807" 
                    outerRadius={100}
                    innerRadius={40}
                    
                    labelLine={false}
                    label={renderCustomizedLabel}
                    cx="50%"
                    cy="50%"
                    dataKey="value"
                  >
                    {option.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} strokeWidth={0}/>
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip />} cursor={{fill: 'rgba(255,255,255, .2)'}} />
                </PieChart>
              </ResponsiveContainer>
            }
        </div>
    )
}

export default PollChart;

