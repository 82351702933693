import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Link, useHistory } from "react-router-dom";
import { motion } from 'framer-motion';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Branding from '../../shared/components/UIElements/Branding';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

import styles from './Holding.module.css';
import HoldingHero from '../components/HoldingHero';


const Holding = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      duration: 5,
      x: -200,
    },
    in: {
      opacity: 1,
      duration: 2,
      x: 0,
    },
    out: {
      opacity: 0,
      duration: 5,
      x: -200,
    },
  };

  const { REACT_APP_API_URL } = process.env;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();
  const [event, setEvent] = useState();
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const changeLanguage = (language) => {
    setLang(language);
    i18n.changeLanguage(language);
  }

  const setLang = (language) => {
    dispatch({ type: 'updateLanguage', lang: language})
  }


  useEffect(() => {
    
      const fetchEvent = async () => {
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/event/events`,
            
          );
          console.log(response);
          if (response.events) {
            if (response.events.length > 0) {
              console.log(response.events[0]);
              setEvent(response.events[0]);
              setTitle(response.events[0].title);
              setSubTitle(response.events[0].subTitle);

              if (!response.events[0].holdingPage) {
                history.push("/auth");
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchEvent();
   
  }, []);

  // check connection status
  


  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <motion.div
        className={styles.pageWrapper}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <Branding
          src="https://eventstreams.co/img/logo.svg"
          alt="client name"
          eventTitle={title}
          subTitle={subTitle}
          width="100px"
          height="65px"
        />

        {event && <HoldingHero event={event} />}
       
       
        {isLoading && (
          <div className={styles.centerSpinner}>
            <LoadingSpinner />
          </div>
        )}
      </motion.div>

     
    </Fragment>
  );
};

export default Holding;
