import React from 'react';
import './MainFooter.css';

const MainFooter = () => {
return (
    <footer>
    <div className="main-footer__shell">
        <div className="main-footer__text">
            <p className="main-footer-text-p ft-title">Terms & Conditions</p>
            <p className="main-footer-text-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et diam at ante suscipit suscipit. Ut a feugiat purus. Phasellus aliquet iaculis nisi eget pulvinar. Pellentesque id augue leo. Phasellus ac viverra magna. Aenean congue ultrices purus, a hendrerit purus tincidunt ut. Fusce euismod lorem in arcu rutrum euismod.</p>
            <p className="main-footer-text-p">Suspendisse eget lorem a lectus volutpat placerat. Nullam rhoncus ante sed vestibulum tincidunt. Donec ac elementum elit. Integer feugiat, enim in condimentum facilisis, nisl lorem posuere leo, ac iaculis libero odio id risus. Curabitur auctor mauris id condimentum porta. Sed quis suscipit libero, sit amet maximus purus. Maecenas ullamcorper at diam vel aliquet. Aenean et nisl feugiat, volutpat mauris sit amet, maximus lectus. Sed vestibulum nisi non massa sollicitudin pellentesque. Quisque condimentum dolor eros, vitae faucibus ante placerat id. Fusce dignissim venenatis erat, a consequat orci fringilla sed.</p>
            <p className="main-footer-text-p jobNumber">Job Number : MGH-0987383</p>
        </div>
       
        <div className="main-footer__bottom">
            <p> &copy; Copyright 2021 - Event Streams - Website Developed by <a className='helogale' href='https://helogale.com' target='blank'>Helogale</a></p>
        </div>

    </div>
    </footer>

)};

export default MainFooter;