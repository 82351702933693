import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import LoginForm from '../components/LoginForm';
import RegisterForm from '../components/RegisterForm';
import ForgotPassword from '../components/ForgotPassword';
import ResetPassword from '../components/ResetPassword';

import styles from './AuthTicket.module.css';

const AuthTicket = props => {

  const pageVariants = {
    initial: {
      opacity: 0,
     
    },
    in: {
      opacity: 1,
      duration:3,
     
    },
    out: {
      opacity: 0,
      duration:3,
      
    },
  }

    return (
          <AnimatePresence exitBeforeEnter>
           
          
            <motion.div 
              key={'authTicket'}
              className="wrapper center col"
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
            > 
            
            <div className={styles.authWrapper}>
            <img className={styles.logo} src={`/logo.svg`} alt="logo" />

            <Switch>

            <Route path="/" exact>
              <LoginForm />
            </Route>
            <Route path="/auth/register" exact>
              <RegisterForm />
            </Route>
            <Route path="/auth/forgot" exact>
             <ForgotPassword />
            </Route>
            <Route path="/auth/reset/:token">
            <ResetPassword />
          </Route>
            <Route path="*">
              <LoginForm />
            </Route>
          </Switch>        
            </div>
            </motion.div>
         
          </AnimatePresence>
        );
};

export default AuthTicket;