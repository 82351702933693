import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import './LangSelectSm.css';

const LangSelectSm = () => {


    const dispatch = useDispatch();

    const langCookie = Cookies.get('i18next')
    if (langCookie !== 'en') {
        dispatch({ type: 'updateLanguage', lang: langCookie})
    }


    const {t, i18n} = useTranslation();
    const changeLanguage = (language) => {
        setLang(language);
        i18n.changeLanguage(language);
    }

    const setLang = (language) => {
        dispatch({ type: 'updateLanguage', lang: language})
    }

    return (
        <div id="lang-sel-sm">
        <nav>
            <ul className="menu">
            <li className="dropdown dropdown-2">
                <FontAwesomeIcon icon={faGlobe} />  <span className='lang-title'>{t('language')}</span>
                <ul className="dropdown_menu dropdown_menu--animated dropdown_menu-2">
                <li className="dropdown_item-1" onClick={()=>changeLanguage('en')}><div className='lang-sub-container'><FontAwesomeIcon icon={faGlobe} /> <span className='lang-name'>English</span></div></li>
                <li className="dropdown_item-2" onClick={()=>changeLanguage('fr')}><div className='lang-sub-container'><FontAwesomeIcon icon={faGlobe} /> <span className='lang-name'>French</span></div></li>
                <li className="dropdown_item-3" onClick={()=>changeLanguage('bg')}><div className='lang-sub-container'><FontAwesomeIcon icon={faGlobe} />  <span className='lang-name'>Български</span></div></li>
                </ul>
            </li>
            </ul>
        </nav>
        </div>
    );
};

export default LangSelectSm;
