import React, {useEffect, useState, useContext, useRef} from 'react';
import Vimeo from '@u-wave/react-vimeo';
import ReactPlayer from 'react-player';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../../components/UIElements/LoadingSpinner';
import ErrorModal from '../../components/UIElements/Modal/ErrorModal';
import styles from './LiveStream.module.css';


const LiveStream = ({ eventId }) => {

  const history = useHistory();
  const  auth  = useContext(AuthContext);
  const { REACT_APP_API_URL } = process.env;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [currentLang, setCurrentLang] = useState('');
  const [liveStream, setLiveStream] = useState();
  const [playing, setPlaying] = useState(true);
  let ref = useRef();

  const [player, setPlayer] = useState();

  useEffect(() => {

    const langCookie = cookies.get('i18next') || 'en';
    setCurrentLang(auth ? auth: langCookie)
   
  }, [auth])

  useEffect(() => {

    if (auth.isLoggedIn) {
      const fetchEvent = async () => {
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/streams`,
            'POST',
            JSON.stringify({
              eventId: eventId,
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token,
            }
          );
          
          if (response.streams) {
           
            if (auth.lang) {
             const stream =  response.streams.find(s => s.lang === auth.lang); 
             setLiveStream(stream);
            } else if (currentLang) {
              const stream =  response.streams.find(s => s.lang === currentLang); 
              setLiveStream(stream);
            } else {
              setLiveStream(response.streams[0])
            }
          }
          
        } catch (err) {
          console.log(err);
        }
       }
       fetchEvent()
     
    } else {
      console.log("Not authorised");
      history.push('/auth');
    }
    

  }, [eventId, currentLang ])

  const fullscreenHandler = () => {
      screenfull.request(findDOMNode(player))
  }

  ref = player => {
    setPlayer(player);
  }

  return (
    <div id={styles.liveStream}>
     <ErrorModal error={error} onClear={clearError} />
      <div id={styles.video}>
        <div className={styles.videoContainer}>
          {isLoading && <LoadingSpinner />}
        
           {!isLoading && liveStream && 
            <ReactPlayer 
              ref={ref}
              url={liveStream.uri}
              playing={playing}
              controls={false}
              volume={0.8}
              pip= {true}
              light={false}
              width='100%'
              height='100%'  
              config={{
                vimeo: {
                  playerOptions: { responsive: true },
                  quality: 'auto'
                  }}}
                
            />}
            <button className={styles.playbtn} type='button' onClick={()=>setPlaying(playing ? false : true)} >{playing ? <FontAwesomeIcon icon={faPause}/> : <FontAwesomeIcon icon={faPlay}/>}</button>
            <button className={styles.fullscreenBtn} type='button' onClick={fullscreenHandler} >{ <FontAwesomeIcon icon={faExpand}/>}</button>
        </div>
      </div>
    </div>
   
  );
};

export default LiveStream;
