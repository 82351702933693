import React, {useContext} from 'react';
import moment from 'moment';
import { motion } from 'framer-motion';

import { AuthContext } from '../../context/auth-context';

import ColoredDot from '../UIElements/ColoredDot';
import QAAnswer from './QAAnswer';

import styles from './QAQuestion.module.css';

const QAQuestion = ({question}) => {

    const auth = useContext(AuthContext);

    function compare(a, b) {
        const ADate = a.createdAt;
        const BDate = b.createdAt;
    
        let comparison = 0;
        if (ADate < BDate) {
          comparison = 1;
        } else if (ADate > BDate) {
          comparison = -1;
        }
        return comparison;
      }


    return (
        <motion.div
        key={question.id}
        id={styles.qWrapper}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
      >
        <div id={styles.leftBodyWrapper}>
          <div className={styles.qContainer}>
            <div className={`${styles.qBody} ${question.user.id === auth.userId && styles.userQuestion}`}>
              <div className={styles.qBodyContent}>
                <h1>
                  {question.user.name === 'Unknown'
                    ? question.user.email
                    : question.user.name}{' '}
                  asks:
                </h1>
                {question.question}
              </div>
            </div>

            <div className={styles.qInfo}>
              <div className={styles.qTimestamp}>
                <p className={styles.qTimestampText}>{moment(question.createdAt).format('LT')}</p>
                <div className={styles.notificationMessage}>
                    {question.selected && <div className={styles.notificationMessageContainer}><ColoredDot color='orange'  blink={!question.answered && true}  /><p>Selected @ {moment(question.selectedAt).format('LT')}</p></div>}
                    {question.answered && <div className={styles.notificationMessageContainer}><ColoredDot color='green' /><p>Answered Live @ {moment(question.answeredAt).format('LT')}</p></div>}
                </div>
              </div>
            </div>
          </div>
          
          {question.qaAnswers &&
            question.qaAnswers.sort(compare).map(a => (
              <QAAnswer key={a.id} answers={a} />
            ))}
        </div>
      </motion.div>
    );
};

export default QAQuestion;