import React, { useState, useEffect } from 'react';

import QAQuestion from './QAQuestion';
import styles from './QABody.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

function compare(a, b) {
  const ADate = a.createdAt;
  const BDate = b.createdAt;

  let comparison = 0;
  if (ADate < BDate) {
    comparison = 1;
  } else if (ADate > BDate) {
    comparison = -1;
  }
  return comparison;
}

const QABody = ({ questionList, userId, sessionState }) => {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (questionList) {
      const sorted = questionList.sort(compare);
      setQuestions(sorted);
    }
  }, [questionList]);

  return (
    <section id={styles.qaBody}>
      { questions.length > 0 && (
        <div>
          {questions.map(q =>
            q.approved ? (
              <QAQuestion key={q.id} question={q} />
            ) : (
              q.user.id === userId && <QAQuestion key={q.id} question={q} />
            )
          )}
        </div>
      ) }
      {sessionState && questions.length === 0 && (
        <div className={styles.noQuestionsWrapper}>
          <div className={styles.noQuestionsContent}>
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faCheckCircle} />
            </span>
            <h1 className={styles.title}>Session is open!</h1>
            <p className={styles.text}>Awaiting questions from the audience</p>
          </div>
        </div>
      )}
      {!sessionState && questions.length === 0 &&(
        <div className={styles.noQuestionsWrapper}>
          <div className={styles.noQuestionsContent}>
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </span>
            <h1 className={styles.title}>Session is Closed!</h1>
            <p className={styles.text}>Check the agenda for Live Q&A times</p>
          </div>
        </div>
      )}

    </section>
  );
};

export default QABody;
