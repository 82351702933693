import React, {Fragment, useContext, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { SocketContext } from '../../shared/context/socket';

import styles from './ForgotPassword.module.css';
import { Link } from 'react-router-dom';
import BackButton from './BackButton';


const ForgotPassword = () => {

    const pageVariants = {
        initial: {
          opacity: 0,
          x: 200,
        },
        in: {
          opacity: 1,
          x: 0, 
        },
        out: {
          opacity: 0,
          x: -200,
        },
    }

    const { REACT_APP_API_URL } = process.env;
    const { t, i18n } = useTranslation();
    const auth = useContext(AuthContext);
    const socket = useContext(SocketContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [requestSent, setRequestSent] = useState(false);
    const [errors, setErrors] = useState();
    const [emailError, setEmailError] = useState(false);

    const submitHandler = async event => {
        event.preventDefault()

        try {
          const res = await sendRequest(
            `${REACT_APP_API_URL}/api/auth/request-token`,
            'POST',
            JSON.stringify({
              email: event.target.email.value
            }),
            {
              'Content-Type': 'application/json',
            }
          );
    
          console.log(res);
          if (res.tokenSuccess) {
            setRequestSent(true);
          }
        } catch (err) {
          console.log(err);
        }
      };


    return (
        <Fragment>
            <ErrorModal error={error} onClear={clearError}/>
            <motion.div 
                  key={'forgot form'}
                  className={styles.formWrapper}
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                > 
        
            <h1 className={styles.formTitle}>{t("forgot password")}</h1>
            {isLoading && <div className='center'><LoadingSpinner /></div>}
            {!isLoading && !requestSent &&
            <form className={styles.form} onSubmit={submitHandler}>
                <div className={styles.inputWrapper}>
                    <label>
                        {t(`email`)}:
                        <span className={emailError ? styles.errorInputLabel : styles.noError}>
                            {errors &&
                            errors.email &&
                            errors.email.type === 'required' &&
                            t(`email is required`) + '!'}
                        </span>
                    </label>
                    <Input id="email" name="email" type="email" placeholder={t(`email`)} inputClassName={`${styles.input} ${emailError ? styles.errorInput : styles.noError}`}/>
                </div>
                <div className={styles.actions}>
                      <Button  type="submit" className={`button-primary ${styles.enterBtn}`}  title={t(`submit`)}/>
                     
                    </div>
              </form>
            }

            
       
       
              {!isLoading && requestSent &&
                <div className={styles.sentWrapper}> 
           
              <p className={styles.sentText}>
                {t('forgot password sent text')}
              </p>

              
              </div>
              }

                <BackButton />
              </motion.div>
    
       </Fragment>
    )
}

export default ForgotPassword;