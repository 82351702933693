import React, { Fragment, useContext, useState }  from 'react';
import { Link, useHistory } from "react-router-dom";
import Bowser from "bowser"; 
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { SocketContext } from '../../shared/context/socket';

import styles from './LoginForm.module.css';

const LoginForm = () => {

    const pageVariants = {
        initial: {
          opacity: 0,
          x: 200,
        },
        in: {
          opacity: 1,
          x: 0, 
        },
        out: {
          opacity: 0,
          x: -200,
        },
    }

    const { REACT_APP_API_URL } = process.env;
    const { t } = useTranslation();
    const auth = useContext(AuthContext);
    const socket = useContext(SocketContext);
    const history = useHistory();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const agentStats = Bowser.parse(window.navigator.userAgent);

    const [errors, setErrors] = useState();
    const [emailError, setEmailError] = useState(false);
    const [passError, setPassError] = useState(false);

    const submitHandler = async event => {
      
        event.preventDefault();
        const email = event.target.email.value || null;
        const password = event.target.password.value || null;

        resetErrors();

        if (email === null) {
          setErrors({ email: { type: 'required' } });
          setEmailError(true)
        } 

        else if (password === null) {
          emailError && setEmailError(()=>false);
          setErrors({ password: { type: 'required' } });
          setPassError(true);
        } else {
    
        const browserName = agentStats.browser.name
        const browserVerion = agentStats.browser.version
        const osName = agentStats.os.name
        const osVersion = agentStats.os.version
        const osVersionName = agentStats.os.versionName
        const platform = agentStats.platform.type
      
    
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/auth/`,
            'POST',
            JSON.stringify({
              email: email,
              password: password,
              browserName: browserName,
              browserVerion: browserVerion,
              osName: osName,
              osVersion: osVersion,
              osVersionName: osVersionName,
              platform: platform
            }),
            {
              'Content-Type': 'application/json',
            }
            );

            let userId;
            if (response.userId) {userId = response.userId}

            socket.auth = { 
              userId,
              email: response.email
            };
            socket.connect();
            auth.login(response.userId, response.email, response.lang, response.token);
            history.push("/");
            
        }
        catch (err) {
          console.log(err);
        }
      }
      }
    
      const navLoginHandler = () => {
        history.push("/auth/register");
      };

      const resetErrors = () => {
        setErrors();
        setEmailError(false);
        setPassError(false);
      }

    return (
        <Fragment>
            <ErrorModal error={error} onClear={clearError}/>
            <motion.div 
                  key={'authTicket'}
                  className={styles.formWrapper}
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                > 
               
                  <h1 className={styles.formTitle}>{t("enter")}</h1>
                  {isLoading && <div className='spinnerWithText'><LoadingSpinner /><p>checking account...</p></div>}
                  {!isLoading &&
                  <form className={styles.form} onSubmit={submitHandler}>
                  <div className={styles.inputWrapper}>
                  <label>
                        {t(`email`)}:
                            <span className={emailError ? styles.errorInputLabel : styles.noError}>
                                {errors &&
                                errors.email &&
                                errors.email.type === 'required' &&
                                t(`email is required`) + '!'}
                            </span>
                        </label>
                    <Input id="email" name="email" type="email" placeholder={t(`email`)} inputClassName={`${styles.input} ${emailError ? styles.errorInput : styles.noError}`}/>
                    <label>{t(`password`)}:
                            <span className={passError ? styles.errorInputLabel : styles.noError}>
                                {errors &&
                                errors.password &&
                                errors.password.type === 'required' &&
                                t(`password is required`) + '!'}
                                {errors &&
                                errors.password &&
                                errors.password.type === 'min char' &&
                                t(`password must contain at least 5 characters`) + '!'}
                            </span>
                        </label>
                    <Input id="password" name="password" type="password" placeholder={t(`password`)} inputClassName={`${styles.input} ${passError ? styles.errorInput : styles.noError}`}/>
                    </div>
                    <div className={styles.actions}>
                      <Button  type="submit" className={`button-primary ${styles.enterBtn}`}  title={t(`enter`)}/>
                      <Button type="button" className={`button-white ${styles.registerBtn}`} title={t(`register`)} onClick={navLoginHandler}/>
                    </div>
                    <div className={styles.backbtnWrapper}>
                      <Link to='/auth/forgot'>{t('forgot password?')}</Link>
                    </div>
                  </form>}
                </motion.div>

        </Fragment>
    )
}

export default LoginForm;