import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import styles from './BackButton.module.css';

const BackButton = () => {

    const { t } = useTranslation();
    
    return (
        <div id={styles.backWrapper}>
        <Link to="/auth">
            <FontAwesomeIcon icon={faArrowLeft}/> { t('back to login')} 
          </Link>
        </div>
    )
}

export default BackButton;