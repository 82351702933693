import React, {Fragment} from 'react';

import TopLinks from './TopLinks';
import LangSelectSm from '../Language/LangSelectSm';
import './MainHeader.css';

const MainHeader = props => {

  return (
    <Fragment>
      <header className="header-container">
        <div className="header-container__shell">
          <div className="header-conatiner__top">  
            <LangSelectSm />
            <TopLinks />
            </div>
        </div>
      </header>
    </Fragment>
  );
};

export default MainHeader;
