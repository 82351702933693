import React, {useState, useEffect, useContext} from 'react'; 
import { Link } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next';

import { SocketContext } from '../../context/socket';
import { AuthContext} from '../../context/auth-context';

import './TopLinks.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';


const TopLinks = ({wallId}) => {

    const auth = useContext(AuthContext);
    const socket = useContext(SocketContext);

    const [ activeWallId, setActiveWallId] = useState()
    const {t, i18n} = useTranslation();

    useEffect(() => {
        wallId && setActiveWallId(wallId)
    }, [wallId])

    const logout = () => {
        socket.emit('logout', {socketId: socket.id})
        auth.logout()
    }

    return (
        <React.Fragment>

            {!auth.isLoggedIn && (
                <ul className="top-links">
                    <li>
                        <FontAwesomeIcon icon={faHeadset}/> <a href="mailTo:support@eventStreams.co">  {t(`support`)}</a>
                    </li>
                </ul>
            )}

            {auth.isLoggedIn && (
                <ul className="top-links">
                    <li>
                        <button className="top-links top-links__logout" onClick={logout}>
                       {t(`logout`)}
                        </button>
                    </li>
                    
                </ul>
            )}
    
    </React.Fragment>
    );
};

export default TopLinks;